import { t } from 'i18next';
import React, { useEffect, useContext, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useParams, useLocation, Link } from 'react-router-dom';


const ThankYouPage = () => {
  const { paymentConfirmed } = useParams();



 

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg text-center">
        {paymentConfirmed ? (
         <>
         <>
  <AiOutlineCheckCircle className="text-green-500 mx-auto h-12 w-12" />
  <h2 className="text-2xl font-semibold mt-4">Payment Confirmed!</h2>
  <p className="text-gray-600 mt-2">Thank you for your purchase.</p>

  {/* Grid of Options */}
  <div className="grid grid-cols-2 gap-8 mt-6 w-full max-w-md mx-auto">
    <Link
      to="/"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
     {t('return_to')}{" "}{t('books_catalogue')}
    </Link>
    <Link
      to="/User/my-printables"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
      {t('return_to')}{" "}{t('my_prinable')}
    </Link>
    <Link
      to="/Bundles"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
      {t('return_to')}{" "}{t('bundles')}
    </Link>
    <Link
      to="/subscriptions"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
     {t('return_to')}{" "}{t('subscriptions')}
    </Link>
    <Link
      to="/pdfviewer"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
      {t('return_to')}{" "}{t('pdf_viewer')}
    </Link>
    <Link
      to="/User"
      className="flex items-center justify-center px-4 py-3 border-2 border-red-800 text-red-800 text-sm font-medium rounded-lg hover:bg-red-800 hover:text-white transition"
    >
      {t('return_to')}{" "}{t('profile')}
    </Link>
  </div>
</>

       </>
       
        ) : (
          <>
            <AiOutlineCheckCircle className="text-gray-400 mx-auto h-12 w-12" />
            <h2 className="text-2xl font-semibold mt-4">Payment Pending</h2>
            <p className="text-gray-600 mt-2">Your payment is being processed.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
