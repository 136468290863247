import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaCartPlus } from "react-icons/fa6";

import { t } from "i18next";
import { PiCursorClick } from "react-icons/pi";
import { Markup } from "interweave";
import BASE_URL from "./constant";
import { AuthContext } from "./context/AuthContext";
import { Link } from "react-router-dom";
import htmlToPlainText from "./htmltotext";


const ProductCard = ({
    
    id,
    image,
    title,
    price,
    symbol,
    discount,
    rating,
   
  }) => {
   
    return (
      <div className="group flex flex-col items-center w-full gap-4 p-5 sm:p-6 border border-gray-300 shadow-md rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:scale-105 bg-white">
        {/* Badge for Discount */}
        {discount && (
          <span className="absolute top-3 right-3 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
            {discount}% OFF
          </span>
        )}
  
        {/* Product Image */}
  
        <div>
          {image ? (
            <img
              src={image}
              alt={title || "Product Image"}
              loading="lazy"
              className="w-full h-[210px] object-contain rounded-lg hover:opacity-90 transition-opacity duration-300"
            />
          ) : (
            <div className="flex items-center justify-center w-full h-[210px] bg-gray-100 text-gray-500 rounded-lg">
              <svg
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 32 32"
                className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <style>{`.cls-1{fill:none;}`}</style>
                </defs>
                <title>no-image</title>
                <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
                <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
                <rect
                  id="_Transparent_Rectangle_"
                  data-name="&lt;Transparent Rectangle&gt;"
                  className="cls-1"
                  width="32"
                  height="32"
                />
              </svg>
            </div>
          )}
        </div>
        {/* </Link> */}
  
        {/* Product Info */}
        <div className="px-4 py-3 w-full">
          {/* Title */}
          <div
            className="group-hover:text-gray-600 transition-colors duration-300 overflow-hidden "
            style={{
              height: "2.5em", // Approximate height for 3 lines with default line height
              lineHeight: "1.5em", // Set line height to calculate height properly
          
            }}
          >
             <Markup content={title}/>
            {/* <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: title }}
            /> */}
          </div>
  
          {/* Price & Discount */}
          <div className="flex items-center justify-between mt-1">
            <p className="text-lg font-semibold text-gray-900"  dir="ltr">
              <span className="text-sm text-gray-500">{symbol}</span> {price}
            </p>
            {discount && (
              <p className="text-sm text-red-500 font-bold line-through">
                <span className="text-xs">{symbol}</span>{" "}
                {(price / (1 - discount / 100)).toFixed(2)}
              </p>
            )}
          </div>
  
          {/* Rating */}
          {rating && (
            <div className="flex items-center mt-2 text-yellow-500">
              {"★".repeat(rating)}
              <span className="ml-2 text-sm text-gray-600">
                {rating}/5 Ratings
              </span>
            </div>
          )}
  
        
           
          <div className="flex flex-col items-center gap-4">
            {/* View Button */}
            <div className="flex justify-center w-full">
              <Link to={`/pages/Product/${id}`} className="w-full max-w-[300px]">
                <button
                  className="flex flex-row items-center justify-center text-center 
          cursor-pointer whitespace-nowrap rounded-[10px] gap-2 border border-red-800 sm:px-5 py-2 text-sm text-red-900 font-semibold hover:bg-red-800 hover:text-white transition-colors duration-300 w-full"
                >
                  {t("view")}
                  <PiCursorClick className="text-lg" />
                </button>
              </Link>
            </div>
         
  
          
          </div>
        </div>
      </div>
    );
  };


// Main Component
const NewArrivalsSlider = () => {
  const [newArrivals, setNewArrivals] = useState([]);
   const [savedLang, setSaveLang] = useState(
     localStorage.getItem("currency") || "usd"
   );
   const [symbol, setSymbol] = useState("$");
   useEffect(() => {
     const svLang = localStorage.getItem("currency");
     setSaveLang(svLang);
    //  console.log(savedLang);
    //  setSymbol(svLang !== "ils" ? "$" : "₪");
    //  if (items.length == 0) {
    //    setPrice(svLang !== "ils" ? product.price : product.price_is);
    //  }
   });
  useEffect(() => {
    const fetchNewArrivals = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api_book.php/newArrival`);
        if (Array.isArray(response.data.data)) {
          setNewArrivals(response.data.data);
        } else {
          console.error("Expected an array of books");
        }
      } catch (error) {
        console.error("Error fetching new arrivals:", error);
      }
    };

    fetchNewArrivals();
  }, []);

  return (
    <div className="container mx-auto py-6 ">
      <h2 className="text-2xl font-bold text-center mb-6 text-[#6D3815]">
       {t("new_arrival")}
      </h2>

      <Swiper
      dir={savedLang === "he" ? "rtl" : "ltr"}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={5}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        breakpoints={{
          320: { slidesPerView: 1 },
          640: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 5 },
        }}
      >
        {newArrivals.map((product) => (
          <SwiperSlide key={product.id}>
            <ProductCard  
          key={product.id}
          id={product.book_id}
          image={product.images[0]}
          title={product.title}
          price={savedLang !== "ils" ? product.price : product.price_is}
          symbol={savedLang !== "ils" ? "$" : "₪"}
          />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default NewArrivalsSlider;
