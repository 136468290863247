import React, { useState, useContext, useEffect } from "react";
import { MdAccountCircle } from "react-icons/md";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import Login from "./Pages/Login";
import Forget from "./Pages/Forget";
import Signup from "./Pages/Signup";
import Collection from "./Pages/Collections";
import Product from "./Pages/Product";
import About from "./Pages/About";
import Contact from "./Pages/Contact";

import Bundles from "./Pages/Bundles";
import User from "./User/User";
import DownloadCatalogue from "./Pages/DownloadCatalogue";
import Donate from "./Pages/Donate";
import BASE_URL from "./constant";
import PDFViewer from "./Pages/PdfViewer";
import Subscriptions from "./Pages/Subscriptions";

import { AuthContext } from "./context/AuthContext";
import Stripepayments from "./Pages/Stripe";
import ThankYouPage from "./Pages/thankyou";
import LanguageSelectorComponent from "./LanguageSelector";
import AjaxCart from "./Ajaxcart";
import { useTranslation } from "react-i18next";
import LanguageCurrencySelect from "./language-selector";
import Privacy from "./Pages/Privacy";
import Checkout from "./Pages/Checkout";
import PrivateRoute from "./privateRoute";
import WalletPage from "./User/walletPage";
import axios from "axios";
import Reset from "./Pages/Reset";
import { BiSupport } from "react-icons/bi";
import UploadBooksForm from "./Pages/UserInsertBook";

const Navbar = () => {
  const { currentUser, getUserData } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("English");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [balance, setBalance] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "he"
  );
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const fontSize = windowWidth < 800 ? "text-[8px]" : "text-[12px]";

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/Collections", label: "books_catalogue" },
    { path: "/Bundles", label: "bundles" },
    { path: "/Subscriptions", label: "subscriptions" },
    { path: "/DownloadCatalogue", label: "catalogue" },
    { path: "/Donate", label: "donate" },
    { path: "/UserInsert", label: "list_book" },
  ];
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    console.log(savedLang);
  });
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const userdata = getUserData();

    // Fetch wallet balance using Axios
    axios
      .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`, {
        params: { user_id: userdata.id }, // Send user ID as a query parameter
      })
      .then((response) => {
        setBalance(response.data.balance); // Assuming the response contains a 'balance' field
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching wallet balance");
        setLoading(false);
      });
  }, [getUserData, currentUser]);
  // Notifier for authentication state changes
  useEffect(() => {
    if (currentUser) {
      console.log("User logged in:", currentUser);
    } else {
      console.log("User logged out");
    }
  }, [currentUser]);

  // Notifier for wallet balance changes
  useEffect(() => {
    if (balance !== null) {
      console.log("Wallet balance updated:", balance);
    }
  }, [balance]);
  const { t } = useTranslation();
  return (
    <>
      <div
        className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2"
        style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px" }}
      >
        {/* Left side logo */}
        <div className="logo">
          <img
            src="https://oizvehadar.com/api/uploads/images/logo.png"
            className="h-[80px]"
            alt="logo"
          />
        </div>

        {/* Middle navigation */}
        <div className="navigation flex-grow flex justify-center">
         {navLinks.map(({ path, label }) => (
        <NavLink
          key={path}
          to={path}
          className={({ isActive }) =>
           path==='/DownloadCatalogue' ?(
            ` mr-4 px-4 h-[40px] rounded-full transform hover:scale-110 transition-colors 
             flex items-center justify-center bg-red-900 hover:bg-red-950 ${fontSize} 
             ${isActive ? "bg-red-950 text-white" : "text-white"} 
             whitespace-nowrap flex-shrink-0 text-center min-w-max`):(`mr-4 w-[120px] h-[40px] rounded-full transform hover:scale-110 transition-colors 
             flex items-center justify-center bg-red-900 hover:bg-red-950 ${fontSize} 
             ${isActive ? "bg-red-950 text-white" : "text-white"}`)
          }
        
        >
          {t(label)}
        </NavLink>
      ))}
    </div>
     

        {!currentUser && (
          <>
            {/* <NavLink to="/Pages/Contact" className="mr-4 px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">Contact us</NavLink> */}
            <NavLink
              to="/Login"
              className="mr-2 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
            >
              <span className=" text-[12px] text-des w-auto">{t("login")}</span>
            </NavLink>
            <NavLink
              className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
              to="/Signup"
            >
              <span className="text-[12px] text-des w-auto">{t("sign")}</span>
            </NavLink>
          </>
        )}

        {currentUser && (
          <>
            <NavLink to="/User">
              <MdAccountCircle
                className={`${
                  savedLang === "en" ? "ml-7" : "mr-7"
                } h-[40px] w-[40px]`}
                style={{ color: "000" }}
              />

              <div className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-center text-[14px] font-bold">
                <NavLink to="/User/wallet">
                  {loading ? (
                    <span className="balance-text">{t("loading")}</span>
                  ) : error ? (
                    <span className="balance-text error">{error}</span>
                  ) : (
                    <span className="balance-text">${balance}</span>
                  )}
                </NavLink>
              </div>
            </NavLink>
          </>
        )}

        <div className="relative flex items-center">
          <div
            className={`flex flex-col items-center ${
              savedLang === "en" ? "ml-2" : "mr-2"
            }`}
          >
            <NavLink
              to="/Contact"
              className="flex flex-col items-center mb-2 group"
            >
              <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
                <BiSupport className="h-[24px] w-[24px] text-white group-hover:text-white" />
              </div>
              <div className="w-[80px] h-[20px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center text-[10px] font-bold text-white mt-1">
                <span className="balance-text">{t("contact")}</span>
              </div>
            </NavLink>

            <LanguageCurrencySelect />
          </div>

          <AjaxCart />
        </div>
      </div>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/Login" Component={Login} />
        <Route path="/reset-password" Component={Reset} />
        <Route path="/Forget" Component={Forget} />
        <Route path="/Signup" Component={Signup} />
        <Route path="/Collections/:id?" Component={Collection} />
        <Route path="/Product" Component={Product} />
        <Route path="/About" Component={About} />
        <Route path="/Contact" Component={Contact} />

        <Route path="/Bundles" Component={Bundles} />
        <Route path="/UserInsert" Component={UploadBooksForm} />
        <Route path="/Pages/Stripe" Component={Stripepayments} />
        <Route path="/Subscriptions" Component={Subscriptions} />
        <Route path="/Pages/Product/:id?" Component={Product} />
        <Route path="/DownloadCatalogue" Component={DownloadCatalogue} />
        <Route path="/Donate" Component={Donate} />
        <Route path="/Checkout" Component={Checkout} />
        {/* Protected PDFViewer route */}
        <Route
          path="/pdfViewer/:id?/:title?"
          element={
            <PrivateRoute>
              <PDFViewer />
            </PrivateRoute>
          }
        />

        <Route path="/User/:id?" Component={User} />
        <Route
          path="/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/"
          Component={ThankYouPage}
        />
        <Route path="/Privacy" Component={Privacy} />
        <Route path="/Checkout" Component={Checkout} />
        <Route path="/wallet" Component={WalletPage} />
      </Routes>
    </>
  );
};

export default Navbar;

// const Navbar = () => {
//   const { currentUser, getUserData } = useContext(AuthContext);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [balance, setBalance] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [savedLang, setSaveLang] = useState(
//     localStorage.getItem("language") || "he"
//   );

//   useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const userdata = getUserData();
//     if (userdata) {
//       axios
//         .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`)
//         .then((response) => {
//           setBalance(response.data.balance);
//           setLoading(false);
//         })
//         .catch(() => {
//           setError("Error fetching wallet balance");
//           setLoading(false);
//         });
//     }
//   }, [getUserData, currentUser]);

//   const { t } = useTranslation();

//   return (
//     <>
//       <div
//         className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2"
//         style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px" }}
//       >
//         {/* Left Logo */}
//         <div className="logo">
//           <img
//             src="https://oizvehadar.com/api/uploads/images/logo.png"
//             className="h-[80px]"
//             alt="logo"
//           />
//         </div>

//         {/* Middle Navigation */}
//         <div className="navigation flex-grow flex justify-center">
//           {[
//             { path: "/", label: "Home" },
//             { path: "/Collections", label: "books_catalogue" },
//             { path: "/Bundles", label: "bundles" },
//             { path: "/Subscriptions", label: "subscriptions" },
//             { path: "/DownloadCatalogue", label: "catalogue" },
//             { path: "/Donate", label: "donate" },
//             { path: "/UserInsert", label: "list_book" },
//           ].map(({ path, label }) => (
//             <NavLink
//               key={path}
//               to={path}
//               className={({ isActive }) =>
//                 `mr-4 w-[120px] h-[40px] rounded-full transform hover:scale-110 transition-colors flex items-center justify-center ${
//                   isActive ? "bg-red-950 text-white" : "bg-red-900"
//                 }`
//               }
//             >
//               {t(label)}
//             </NavLink>
//           ))}
//         </div>

//         {/* Auth Links */}
//         {!currentUser ? (
//           <>
//             <NavLink
//               to="/Login"
//               className="mr-2 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
//             >
//               {t("login")}
//             </NavLink>
//             <NavLink
//               to="/Signup"
//               className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
//             >
//               {t("sign")}
//             </NavLink>
//           </>
//         ) : (
//           <>
//             <NavLink to="/User" className="flex items-center">
//               <MdAccountCircle
//                 className={`${
//                   savedLang === "en" ? "ml-7" : "mr-7"
//                 } h-[40px] w-[40px]`}
//               />
//               <div className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-[14px] font-bold">
//                 <NavLink to="/User/wallet">
//                   {loading ? (
//                     <span className="balance-text">{t("loading")}</span>
//                   ) : error ? (
//                     <span className="balance-text error">{error}</span>
//                   ) : (
//                     <span className="balance-text">${balance}</span>
//                   )}
//                 </NavLink>
//               </div>
//             </NavLink>
//           </>
//         )}

//         {/* Right Side Icons & Language Selector */}
//         <div className="relative flex items-center">
//           <div
//             className={`flex flex-col items-center ${
//               savedLang === "en" ? "ml-2" : "mr-2"
//             }`}
//           >
//             <NavLink
//               to="/Contact"
//               className="flex flex-col items-center mb-2 group"
//             >
//               <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
//                 <BiSupport className="h-[24px] w-[24px] text-white" />
//               </div>
//               <div className="w-[80px] h-[20px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center text-[10px] font-bold text-white mt-1">
//                 {t("contact")}
//               </div>
//             </NavLink>

//             <LanguageCurrencySelect />
//           </div>

//           <AjaxCart />
//         </div>
//       </div>

//       {/* Routes */}
//       <Routes>
//         <Route path="/" Component={Home} />
//         <Route path="/Login" Component={Login} />
//         <Route path="/Signup" Component={Signup} />
//         <Route path="/Collections" Component={Collection} />
//         <Route path="/Product" Component={Product} />
//         <Route path="/Contact" Component={Contact} />
//         <Route path="/Bundles" Component={Bundles} />
//         <Route path="/UserInsert" Component={UploadBooksForm} />
//         <Route path="/Subscriptions" Component={Subscriptions} />
//         <Route path="/DownloadCatalogue" Component={DownloadCatalogue} />
//         <Route path="/Donate" Component={Donate} />
//         <Route path="/Checkout" Component={Checkout} />
//         <Route path="/User/:id?" Component={User} />
//         <Route path="/wallet" Component={WalletPage} />
//         <Route
//           path="/pdfViewer/:id?/:title?"
//           element={
//             <PrivateRoute>
//               <PDFViewer />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/"
//           Component={ThankYouPage}
//         />
//         <Route path="/Privacy" Component={Privacy} />
//       </Routes>
//     </>
//   );
// };

// export default Navbar;
