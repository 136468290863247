// import React, { useState,useEffect ,useContext} from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// // import { Link } from "react-router-dom";
// import axios from "axios";
// import BASE_URL from "../constant";
// import { useTranslation } from 'react-i18next';
// import { AuthContext } from "../context/AuthContext";
// import { ToastContainer, toast } from 'react-toastify';
// import htmlToPlainText from "../htmltotext";

// const ProductCard = ({ image, title, price }) => {
//   return (
//     <div className="grid grid-cols-2 gap-0 rounded-lg shadow-md">
//       <div className="bg-white p-4 ">
//         <img src={image} alt={title} className="mb-2 h-[100px]" />
//       </div>
//       <div className="bg-white p-4">
//         <h3 className="text-lg font-semibold">{title}</h3>
//         {/* <h2 className="text-lg font-semibold">${price}</h2> */}
//       </div>
//     </div>
//   );
// };

// const ProductList = ({ filteredProducts }) => {
//   return (
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//       {/* Render product cards */}
//       {filteredProducts.map((product) => (
//         <ProductCard key={product.id} image={product.images[0]} title={htmlToPlainText(product.title)} price={product.price} />
//       ))}
//     </div>
//   );
// };

// const Subscriptions = () => {
//   const navigate = useNavigate();
//   const {cart, addBundleToCart, removeFromCart, currentUser, getUserData } = useContext(AuthContext);
//   const [topics, setTopics] = useState([]);
//   const [subscription_price, setSubscriptionPrice] = useState(0);

//     useEffect(() => {
//       fetchSubscriptions();
//     }, []);

//     const fetchSubscriptions = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api_subscription.php`);
//         const bookData = response.data;

//         if (Array.isArray(bookData)) {
//           setAllTopics(bookData);
//           setTopics([bookData[0]]);
//           setSubscriptionPrice(bookData[0].price);
//           handleFilterChange(bookData[0]);

//         } else {
//           console.error('Expected an array of books');
//         }
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };
//     const [allTopics,setAllTopics] = useState([]); // Array of all topics

//     const handleTopicChange = (topic) => {
//       console.log(topic);
//       setTopics([topic]);
//       setSubscriptionPrice(topic.price);
//        handleFilterChange(topic); // Set topics array with only the selected topic

//     };
//   const [products,setProduct] = useState([]);

//   const purchase = async (e,pro) => {
//     e.preventDefault();
//     console.log(pro);
//     if(currentUser){
//       navigate('/pages/Stripe',{state:{
//         product:pro,
//         amount:subscription_price,
//         type:"subscription"
//        }} );
//             }else{
//               toast.error(`Your need to login first`);
//             }
//     // addBundleToCart(pro);

//   };

//   const handleFilterChange = (selectedTopics) => {
//     console.log(selectedTopics);
//     console.log(selectedTopics.books);
//     setProduct(selectedTopics.books)

//     console.log(products);

//   };

//   const {t}=useTranslation();

//   return (
//     <div>
//      <div className="container mx-auto px-4 py-8 lg:flex w-full">
//   <div className="pr-4 collection-filter lg:w-[25%]">
//   <div className="bg-gray-100 p-4  sticky top-0 z-50">
//         {/* Your filters content goes here */}
//         <h2 className="text-lg font-semibold mb-2 col-span-2">{t('buy_sub')}</h2>
//        <div className="grid grid-cols-2 gap-4">
//        {allTopics.map((topic) => (
//     <label key={topic.id} className="inline-flex items-center">
//         <input
//             type="checkbox"
//             className="hidden"
//             onChange={() => handleTopicChange(topic)}
//             checked={topics.includes(topic)}
//         />
//         <span
//             className={`${
//                 topics.includes(topic)
//                     ? "bg-red-500 text-white"
//                     : "bg-black-200 text-white-700"
//             } px-4 flex py-2 border text-[12px] rounded-lg focus:outline-none cursor-pointer`}
//         >
//             {topic.title}
//         </span>
//     </label>
// ))}

// </div>
//       </div>
//     {/* <Filters onFilterChange={handleFilterChange} /> */}
//   </div>
//   <div className="collection-prod lg:w-[60%]">
//     <ProductList filteredProducts={products} />
//   </div>
//   <div className="pl-4 total-price lg:w-[15%]" style={{ marginTop: "20px" }}> {/* Adjust margin as needed */}
//   <div className="bg-gray-100 p-4 sticky top-10"> {/* Lowered top positioning */}
//       <h2 className="text-lg font-semibold mb-2">{t('sub_price')}: ${subscription_price} {t('per_month')}</h2>
//       <button className="bg-red-900 text-white px-4 py-2 rounded-lg focus:outline-none" onClick={(e) => purchase(e, topics)}>
//           {t('buy_sub')}
//       </button>
//   </div>
// </div>

// </div>
//     </div>
//   );
// };

// export default Subscriptions;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../constant";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import htmlToPlainText from "../htmltotext";

const Subscriptions = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_subscription.php`);
      if (Array.isArray(response.data)) {
        setSubscriptions(response.data);
      } else {
        console.error("Expected an array of subscriptions");
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const handlePurchase = (subscription) => {
    if (!currentUser) {
      toast.error("You need to login first");
      return;
    }
    navigate("/pages/Stripe", {
      state: {
        product: subscription,
        amount: subscription.price,
        type: "subscription",
      },
    });
  };

  return (
    <div className="container min-h-[800px] mx-auto ">
      <h1 className="text-3xl font-bold text-center mt-8 mb-4">
        {" "}
        {t("subscriptions")}
      </h1>
      <div className="container mx-auto px-4 py-8 lg:flex w-full ">
        {/* <div className="flex mx-auto justify-center items-center"> */}
        {/* <div className="grid-container overflow-y-auto h-[650px] w-full"> */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {subscriptions.map((subscription) => (
              <div
                key={subscription.id}
                className="flex flex-col rounded-2xl bg-white text-red-900 shadow-xl w-full "
              >
                {/* <img
              src={""}
              alt={subscription.title}
              className="rounded-t-2xl w-full h-40 object-cover"
            /> */}
                <div
                  className="rounded-t-2xl bg-[#fce5c4c4] w-full h-40"
                  style={{
                    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                  }}
                >
                  <img
                    src="https://oizvehadar.com/api/uploads/images/logo.png"
                    className="rounded-t-2xl w-full h-full object-contain"
                    alt="logo"
                  />
                </div>
                <div class="flex flex-col p-8 h-full">
                  <div class="text-3xl  font-bold pb-6">
                    {" "}
                    {subscription.title}
                  </div>
                  <div class="  text-lg pb-6"> {subscription.description}</div>
                  {/* <div class="  text-lg pb-6"> {subscription.description}</div> */}
                  <div className="flex flex-row justify-between items-center">
                    <div
                      className={`text-lg font-semibold mb-2 ${
                        isRtl ? "text-right" : "text-left"
                      }`}
                    >
                      {t("no_books")}:
                    </div>
                    {/* <br></br> */}
                    <div
                      className={`text-m font-semibold mb-2 ${
                        isRtl ? "text-left" : "text-right"
                      }`}
                    >
                      {subscription.books.length}
                    </div>
                  </div>

                  <div
                    className={`text-lg font-semibold mb-2 ${
                      isRtl ? "text-right" : "text-left"
                    }`}
                  >
                    {t("sub_price")}:
                  </div>

                  {/* <br></br> */}
                  <div
                    className={`text-m font-semibold mb-2 ${
                      isRtl ? "text-left" : "text-right"
                    }`}
                  >
                    ${subscription.price} {t("per_month")}
                  </div>
                  {/* </div> */}

                  <div
                    className="flex flex-col gap-3 text-base overflow-y-auto "
                    style={{ height: "150px" }}
                  >
                    {subscription.books.map((book, index) => (
                      <div
                        key={index}
                        className="flex flex-row gap-2 items-center"
                      >
                        <img
                          src={book.images[0]}
                          alt={htmlToPlainText(book.title)}
                          className="w-16 h-16 object-contain" // Smaller image size
                        />
                        <div className="text-sm">
                          {htmlToPlainText(book.title)}
                        </div>{" "}
                        {/* Smaller text size */}
                      </div>
                    ))}
                  </div>

                  <div class="flex flex-grow"></div>
                  <div class="flex pt-10">
                    <button
                      className="w-full bg-red-700 text-white font-bold text-base p-3 rounded-lg hover:bg-red-800 active:scale-95 transition-transform"
                      onClick={() => handlePurchase(subscription)}
                    >
                      {t("buy_sub")}
                    </button>
                  </div>
                </div>
              </div>
              // </div>
            ))}
          </div>
          {/* </div> */}
          <ToastContainer />
        </div>
      </div>
    // </div>
  );
};

export default Subscriptions;
