import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import BASE_URL from "../constant";
import {
  FaOpencart,
  FaPrint,
  FaSearch,
  faSearchPlus,
  faSearchMinus,
  faUnd,
  FaSearchPlus,
  FaSearchMinus,
  FaUndo,
  FaBackspace,
  FaRedo,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "../htmltotext";
import ReactDOM from "react-dom";
import {
  MdArrowBackIos,
  MdCreateNewFolder,
  MdDriveFileMove,
  MdPrint,
} from "react-icons/md";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  FaFolder,
  FaFileAlt,
  FaList,
  FaTh,
  FaPlus,
  FaEdit,
  FaTrash,
  FaTimes,
} from "react-icons/fa";
import convertToHebrewNumerals from "../hebrewnumbers";
import { t } from "i18next";
import { UpArrowIcon } from "@react-pdf-viewer/page-navigation";

const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState(1);

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  const handleResetZoom = () => {
    setScale(1);
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-[1000] p-4"
      dir="ltr"
      onKeyDown={(e) => e.key === "Escape" && onClose} // Close on Esc
      tabIndex={-1}
    >
      <div className="relative bg-white p-4 rounded max-h-[100vh]">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-red-900 text-white px-2 rounded-full"
          title="Close Window"
        >
          X
        </button>
        <br />
        <div className="flex justify-center items-center overflow-auto max-h-[80vh]">
          <img
            src={imageSrc}
            alt="Preview"
            style={{ transform: `scale(${scale})` }}
            className="max-w-full max-h-[80vh] transition-transform duration-200"
          />
        </div>
        <div className="flex justify-center mt-2">
          <button
            onClick={handleZoomIn}
            className="mx-2 bg-red-900 text-white px-4 py-2 rounded-full"
            title="Zoom In"
          >
            <FaSearchPlus />
          </button>
          <button
            onClick={handleZoomOut}
            className="mx-2 bg-red-900 text-white px-4 py-2 rounded-full"
            title="Zoom Out"
          >
            <FaSearchMinus />
          </button>
          <button
            onClick={handleResetZoom}
            className="mx-2 bg-gray-500 text-white px-4 py-2 rounded-full"
            title="Reset Zoom"
          >
            <FaUndo />
          </button>
        </div>
      </div>
    </div>
  );
};

const MyPrintables = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [page_no, setPageNo] = useState();

  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { getUserData } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");
  const [hebrewSort, setHebrewSort] = useState(false);
  const [alllang, setAllLang] = useState(
    localStorage.getItem("language") === "en" ? "All" : "הכל" || "All"
  );

  useEffect(() => {
    const userdata = getUserData();
    console.log(userdata.id); // Ensure the userdata has an id field

    GetPrints(userdata);
    GetCategories();
  }, []);
  const updatetitles = async (orderData) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api_book.php/getBooksByCategory?category=${orderData}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response.data); // Log the entire response

      if (Array.isArray(response.data.books)) {
        // Extract book titles from the API response
        let books = response.data.books.map((book) =>
          book.trim().toLowerCase()
        );

        console.log("Books after cleanup:", books); // Log the cleaned book titles

        // Check if invoices array is populated
        console.log("Invoices:", invoices); // Log the invoices array

        let filteredOnlyPage = (invoices.onlyPage || []).filter((page) =>
          books.includes((page.title || "").trim().toLowerCase())
        );

        let extractedPages = new Set(filteredOnlyPage.map((p) => p.id));

        // Filter folders and retain only those containing matching pages
        let filteredFolders = (invoices.folders || [])
          .map((folder) => {
            let matchingPages = folder.pages.filter((page) =>
              extractedPages.has(page.id)
            );

            return matchingPages.length > 0
              ? { ...folder, pages: matchingPages }
              : null;
          })
          .filter(Boolean); // Remove null values (folders with no matching pages)

        console.log("Filtered Folders:", filteredFolders);
        console.log("Filtered Pages:", filteredOnlyPage);

        setFilteredInvoices({
          folders: filteredFolders,
          onlyPage: filteredOnlyPage,
        });
      } else {
        console.error("Expected an array of books from the API");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const GetCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_categories.php`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (Array.isArray(response.data)) {
        setSelectedCategories(response.data);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const GetPrints = async (orderData) => {
    if (!orderData || !orderData.id) {
      console.error("Invalid order data or missing ID");
      return;
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/api_print.php?action=byfolders&user_id=${orderData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Prints successfully fetched:", response.data);
      // if (Array.isArray(response.data)) {
      setInvoices(response.data);
      setFilteredInvoices(response.data);
      // } else {
      //   console.error("Expected an array of books");
      // }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const filterInvoices = () => {
    if (!searchTerm) {
      setFilteredInvoices({ ...invoices }); // Reset if no search term
      return;
    }

    let filtered = { ...invoices };
    let extractedPages = [];

    // Filter folders and extract matching pages
    filtered.folders = invoices.folders
      .map((folder) => {
        const filteredPages = folder.pages.filter((page) => {
          const matches = htmlToPlainText(page.title)
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          if (matches) extractedPages.push(page); // Collect matching pages
          return matches;
        });

        // Return the folder only if its name matches the search term OR it has matching pages
        if (
          htmlToPlainText(folder.folder)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          filteredPages.length > 0
        ) {
          return { ...folder, pages: filteredPages };
        }

        return null; // Remove non-matching folders
      })
      .filter(Boolean); // Remove null folders // Remove null folders

    // Filter `onlyPage` based on the search term
    let filteredOnlyPage = invoices.onlyPage.filter((page) =>
      htmlToPlainText(page.title)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    // Merge `onlyPage` ensuring uniqueness
    filtered.onlyPage = [
      ...new Map(
        [...filteredOnlyPage, ...extractedPages].map((p) => [p.id, p])
      ).values(),
    ];

    setFilteredInvoices({
      ...filtered,
      folders: filtered.folders.filter((folder) =>
        htmlToPlainText(folder.folder || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ),
      onlyPage: filtered.onlyPage,
    });
  };

  useEffect(() => {
    filterInvoices();
  }, [searchTerm]);
  useEffect(() => {
    if (selectedCategory != "All" && selectedCategory != "") {
      updatetitles(selectedCategory);
    } else {
      setFilteredInvoices(invoices);
    }
  }, [selectedCategory]);
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setAllLang(svLang === "en" ? "All" : "הכל");
  });

  useEffect(() => {
    if (hebrewSort) {
      // Ensure invoices exist
      if (!filteredInvoices) return;

      // Sort the "onlyPage" array
      const sortedOnlyPage = [...(filteredInvoices.onlyPage || [])].sort(
        hebrewSorter
      );

      // Sort the pages within each folder
      const sortedFolders = (filteredInvoices.folders || []).map((folder) => ({
        ...folder,
        pages: [...folder.pages].sort(hebrewSorter), // Sort pages inside folders
      }));

      console.log("Sorted Folders:", sortedFolders);
      console.log("Sorted OnlyPage:", sortedOnlyPage);

      setFilteredInvoices({ folders: sortedFolders, onlyPage: sortedOnlyPage });
    } else {
      setFilteredInvoices(invoices);
    }
  }, [hebrewSort]);

  useEffect(() => {
    if (page_no) {
      let filtered = { ...invoices };
      let extractedPages = [];

      // Filter folders and extract matching pages based on page_no
      filtered.folders = (invoices.folders || [])
        .map((folder) => {
          const filteredPages = (folder.pages || []).filter(
            (page) => page.page_no == page_no
          );

          if (filteredPages.length > 0) {
            extractedPages.push(...filteredPages); // Collect matching pages
            return { ...folder, pages: filteredPages };
          }

          return null; // Remove non-matching folders
        })
        .filter(Boolean); // Remove null folders

      // Filter `onlyPage` based on `page_no`
      let filteredOnlyPage = (invoices.onlyPage || []).filter(
        (page) => page.page_no == page_no
      );

      // Merge `onlyPage` ensuring uniqueness
      filtered.onlyPage = [
        ...new Map(
          [...filteredOnlyPage, ...extractedPages].map((p) => [p.id, p])
        ).values(),
      ];

      setFilteredInvoices({
        ...filtered,
        folders: filtered.folders,
        onlyPage: filtered.onlyPage,
      });
    } else {
      setFilteredInvoices(invoices); // If no `page_no` is provided, show all invoices
    }
  }, [page_no, invoices]);
  // Add dependencies for `invoices` and `page_no` to ensure the filter is recalculated correctly

  function hebrewSorter(a, b) {
    const hebrewOrder = {
      א: 1,
      ב: 2,
      ג: 3,
      ד: 4,
      ה: 5,
      ו: 6,
      ז: 7,
      ח: 8,
      ט: 9,
      י: 10,
      כ: 11,
      ל: 12,
      מ: 13,
      נ: 14,
      ס: 15,
      ע: 16,
      פ: 17,
      צ: 18,
      ק: 19,
      ר: 20,
      ש: 21,
      ת: 22,
    };

    // Remove HTML tags and get the first letter of each title
    const aTitle = htmlToPlainText(a.title);
    const bTitle = htmlToPlainText(b.title);

    const aFirstLetter = aTitle.charAt(0);
    const bFirstLetter = bTitle.charAt(0);

    // Perform comparison based on the Hebrew order
    if (hebrewOrder[aFirstLetter] && hebrewOrder[bFirstLetter]) {
      if (hebrewOrder[aFirstLetter] === hebrewOrder[bFirstLetter]) {
        return 0;
      }

      return hebrewOrder[aFirstLetter] < hebrewOrder[bFirstLetter] ? -1 : 1;
    }

    // Handle cases where the first letter is not in the Hebrew order array
    return 0; // You can modify this part to define behavior for such cases
  }

  const printPage = (imageSrc, title) => {
    const printWindow = window.open("", "", "height=600,width=800");

    // Create the HTML structure with inline CSS to control the print layout
    const printContent = `
        <html>
       //<meta http-equiv="Content-Security-Policy" content="script-src 'self'">

          <head>
          
            <title>${title}</title>
            
            <style>
              @media print {
                @page {
                  size: auto;
                  margin: 0;
                }
                html, body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                canvas {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            </style>
          </head>
          <body>
            <canvas id="printCanvas"></canvas>
            <script>
              const canvas = document.getElementById('printCanvas');
              const ctx = canvas.getContext('2d');
              const img = new Image();
              img.src = '${imageSrc}';
             img.onload = function() {
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  setTimeout(() => {
    window.print();
    window.close();
  }, 100); // Small delay before printing
};
            </script>
          </body>
        </html>
      `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  };
  const printPages = (imageList) => {
    const printWindow = window.open("", "", "height=600,width=800");

    const printContent = `
        <html>
          <head>
            <title>Print Images</title>
            <style>
              @media print {
                @page {
                  size: auto;
                  margin: 0;
                }
                html, body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }
                .print-container {
                  width: 100%;
                  page-break-after: always;
                  text-align: center;
                }
                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            </style>
          </head>
          <body>
            ${imageList
              .map(
                ({ imageSrc, title }) => `
                <div class="print-container">
                
                  <img src="${imageSrc}" />
                </div>
              `
              )
              .join("")}
            <script>
              window.onload = function() {
                setTimeout(() => {
                  window.print();
                  window.close();
                }, 100);
              };
            </script>
          </body>
        </html>
      `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  const viewPage = (imageSrc) => {
    setCurrentImageSrc(imageSrc); // Set the current image source for the modal
    setModalOpen(true); // Open the modal to view the image
  };
  const removePage = (bookId, pageNo) => {
    console.log(bookId, pageNo);
    setFilteredInvoices((prevPages) => ({
      ...prevPages,
      onlyPage: prevPages.onlyPage.filter(
        (page) => !(page.id === bookId && page.page_no === pageNo)
      ),
    }));
  };

  const [view, setView] = useState("grid");
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between px-20 py-4 space-y-4 md:space-y-0 bg-red-900 rounded-lg shadow-lg">
        {/* Search Book */}
        <label htmlFor="searchBook" className="font-medium text-white">
          {t("search_book_print")}
        </label>
        <input
          type="text"
          placeholder={t("search")}
          className="p-2 border rounded w-full md:w-1/2 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="flex flex-col space-y-6 px-20 py-4">
          {/* Select Category and Hebrew Sort Toggle */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-x-4 md:space-y-0">
            <div className="flex flex-col space-y-2">
              <label htmlFor="category" className="font-medium text-white">
                {t("select_cate")}
              </label>
              <select
                id="category"
                className="p-2 border rounded w-full  bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedCategory}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedCategory(e.target.value);
                }}
              >
                <option value="All">{alllang}</option>
                {selectedCategories.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Input Fields for Page Range */}
          <div className="flex space-x-4 w-full">
            {/* From Page Input */}
            <div className="flex flex-col w-full md:w-1/2">
              <label htmlFor="fromPage" className="font-medium text-white">
                {t("by_page")}
              </label>
              <input
                id="fromPage"
                type="text"
                placeholder={t("page")}
                className="p-2 border rounded w-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={page_no}
                onChange={(e) => setPageNo(e.target.value)}
              />
            </div>

            {/* Hebrew Sort Toggle */}
            <div className="flex flex-col items-center space-y-2 w-full md:w-1/2">
              <label htmlFor="hebrewSort" className="font-medium text-white">
                {t("order_hebrew")}
              </label>
              <input
                id="hebrewSort"
                type="checkbox"
                className="w-6 h-6"
                checked={hebrewSort}
                onChange={() => setHebrewSort(!hebrewSort)}
              />
            </div>
          </div>
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        {filteredInvoices ? (
          <FileManagers
            pageNumbersMap={filteredInvoices.onlyPage}
            savedLang={"en"}
            folders={filteredInvoices.folders}
            printPage={printPage}
            viewPage={viewPage}
            setFolders={setFilteredInvoices}
            deletepage={removePage}
            views={view}
            setviews={setView}
            getUserData={getUserData}
            GetPrints={GetPrints}
            printPages={printPages}
          />
        ) : (
          <p>{t("noitemfound")}</p>
        )}
      </DndProvider>

      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        imageSrc={currentImageSrc}
      />
    </>
  );
};
const FileManagers = ({
  pageNumbersMap,
  savedLang,
  folders,
  setFolders,
  deletepage,
  views,
  setviews,
  printPage,
  viewPage,
  getUserData,
  GetPrints,
  printPages,
}) => {
  // const [folders, setfolders] = useState([]);
  const [pages, setPages] = useState([]);
  const [fileStructure, setFileStructure] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [isMovePopupOpen, setIsMovePopupOpen] = useState(false);

  const handleMove = async () => {
    console.log(selectedFolder, selectedFiles);

    try {
      const params = new URLSearchParams();
      params.append("action", "add_page_in_folder");
      params.append("folder_id", selectedFolder);

      // Ensure selectedFiles is an array before appending
      if (Array.isArray(selectedFiles)) {
        selectedFiles.forEach((fileId) => {
          params.append("id[]", fileId); // Correctly formatting array in URLSearchParams
        });
      } else {
        params.append("id[]", selectedFiles); // In case only a single ID is selected
      }

      const response = await axios.put(`${BASE_URL}/api_print.php`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Response:", response.data);
      GetPrints(getUserData()); // Refresh the data after update
      setIsMovePopupOpen(false); // Close popup after moving
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    setPages(pageNumbersMap);
  }, [pageNumbersMap]);
  useEffect(() => {
    console.log(folders);
  }, [folders]);
  const toggleSelectFile = (fileName) => {
    console.log(selectedFiles);
    setSelectedFiles((prev) =>
      prev.includes(fileName)
        ? prev.filter((file) => file !== fileName)
        : [...prev, fileName]
    );
  };

  const toggleSelectAll = () => {
    if (selectedFiles.length === pages.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(pages.map((file) => file.print_page_id));
    }
  };

  const createFolder = async () => {
    const folderName = prompt("Enter folder name:");
    const userdata = getUserData();
    console.log(userdata.id);
    if (folderName) {
      try {
        const response = await axios.post(
          `${BASE_URL}/api_print.php`,
          {
            folder: folderName,
            user_id: userdata.id,
            action: "create_folder",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Response:", response.data);
        GetPrints(userdata);
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  const renameFolder = async (index) => {
    const newName = prompt("Enter new folder name:", folders[index]?.name);
    if (!newName) return;

    try {
      const formData = new URLSearchParams();
      formData.append("action", "folder");
      formData.append("id", folders[index]?.id || "");
      formData.append("folder_name", newName);

      const response = await axios.put(`${BASE_URL}/api_print.php`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Response:", response.data);

      const userdata = getUserData();
      if (userdata?.id) {
        console.log("User ID:", userdata.id);
        GetPrints(userdata);
      }
    } catch (error) {
      console.error("Error:", error?.response?.data || error.message);
    }
  };

  const deleteFolder = async (index) => {
    if (window.confirm("Are you sure you want to delete this folder?")) {
      // setFolders(folders.filter((_, i) => i !== index));
      try {
        const response = await axios.delete(`${BASE_URL}/api_print.php`, {
          data: new URLSearchParams({
            action: "delete_folder",
            id: folders[index].id,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        console.log("Response:", response.data);
        GetPrints(getUserData()); // Refresh the list
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
      }
    }
  };

  return (
    <div className="p-4" dir={savedLang !== "he" ? "ltr" : "rtl"}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">{t("my_printable_explorer")}</h2>
        <div className="flex gap-2">
          {selectedFiles.length > 0 && (
            <button
              className="flex justify-center w-full p-3 border border-gray-400 rounded-md items-center gap-2 h-12"
              onClick={() => setIsMovePopupOpen(true)}
            >
              <MdDriveFileMove />
              {t("moveTo")}
            </button>
          )}

          <label className="flex justify-center w-full p-3 border border-gray-400 rounded-md items-center gap-2 h-12 cursor-pointer">
            {t("select_all")}
            <input
              className="ml-2 accent-red-700 cursor-pointer"
              type="checkbox"
              onChange={toggleSelectAll}
              checked={
                pages && pages.length > 0
                  ? selectedFiles.length === pages.length
                  : false
              }
            />
          </label>
          <button
            onClick={createFolder}
            className="flex justify-center w-full p-2 border border-gray-400 rounded-md items-center gap-2 h-12 "
          >
            <MdCreateNewFolder />
            {t("new_folder")}
          </button>
          <button
            className="border border-gray-400 rounded-md p-3 flex justify-center items-center w-12 h-12"
            onClick={() => setviews("list")}
          >
            <FaList />
          </button>
          <button
            className="border border-gray-400 rounded-md p-3 flex justify-center items-center w-12 h-12"
            onClick={() => setviews("grid")}
          >
            <FaTh />
          </button>
        </div>
      </div>

      <div
        style={{
          height: "550px",
          overflowY: "auto",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        {views === "list" ? (
          <div className="border rounded-lg overflow-hidden">
            {/* Table Header */}
            <div className="grid grid-cols-2 bg-gray-100 p-3 font-bold border-b">
              <div>Name</div>
              <div className="flex justify-end space-x-2">Actions</div>
            </div>
            {/* List Items */}
            <div className="divide-y">
              {folders?.length === 0 && pages?.length === 0 ? (
                <p className="text-center text-gray-500 py-4">
                  {t("no_file_or_folder")}
                </p>
              ) : (
                <>
                  {folders?.map((folder, index) => (
                    <DroppableFolder
                      key={folder.id}
                      folder={folder}
                      setFolders={setFolders}
                      index={index}
                      deletepage={deletepage}
                      renameFolder={renameFolder}
                      deleteFolder={deleteFolder}
                      page={pageNumbersMap}
                      setPages={setPages}
                      islist={views}
                      getUserData={getUserData}
                      GetPrints={GetPrints}
                      printPage={printPage}
                      viewPage={viewPage}
                      printPages={printPages}
                      savedLang={savedLang}
                    />
                  ))}

                  {pages?.length > 0 &&
                    pages?.map((book) => (
                      <DraggableFile
                        key={book.id}
                        file={book}
                        setPages={setPages}
                        savedLang={savedLang}
                        islist={views}
                        viewPage={viewPage}
                        printPage={printPage}
                        deletepage={deletepage}
                        toggleSelectFile={toggleSelectFile}
                        selectedFiles={selectedFiles}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-4">
            {folders?.length === 0 && pages?.length === 0 ? (
              <div className="col-span-4 text-center text-gray-500 py-4">
                {t("no_file_or_folder")}
              </div>
            ) : (
              <>
                {folders?.map((folder, index) => (
                  <DroppableFolder
                    key={folder.id}
                    folder={folder}
                    setFolders={setFolders}
                    index={index}
                    islist={views}
                    deletepage={deletepage}
                    page={pageNumbersMap}
                    renameFolder={renameFolder}
                    deleteFolder={deleteFolder}
                    setPages={setPages}
                    getUserData={getUserData}
                    GetPrints={GetPrints}
                    printPage={printPage}
                    viewPage={viewPage}
                    printPages={printPages}
                    savedLang={savedLang}
                  />
                ))}

                {pages?.map((book) => (
                  <DraggableFile
                    key={book.id}
                    file={book}
                    setPages={setPages}
                    savedLang={savedLang}
                    islist={views}
                    viewPage={viewPage}
                    printPage={printPage}
                    deletepage={deletepage}
                    toggleSelectFile={toggleSelectFile}
                    selectedFiles={selectedFiles}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <br></br>
      <br></br>
      {isMovePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-80">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t("select_folder")}</h3>
              <button
                className="text-xl"
                onClick={() => setIsMovePopupOpen(false)}
              >
                ✖
              </button>
            </div>

            <select
              className="w-full border p-2 rounded-md mb-4"
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
            >
              <option value="">{t("choose_folder")}</option>
              {folders.map((folder, index) => (
                <option key={index} value={folder.id}>
                  {folder.folder}
                </option>
              ))}
            </select>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md w-full"
              onClick={handleMove}
              disabled={!selectedFolder}
            >
              {t("send")}
            </button>
          </div>
        </div>
      )}
    </div>

    // </DndProvider>
  );
};

const DraggableFile = ({
  file,
  savedLang,
  islist,
  viewPage,
  printPage,
  selectedFiles,
  toggleSelectFile,
  nocheck = false,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "file",
    item: file,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const isChecked =
    file.print_page_id && selectedFiles?.includes(file.print_page_id);
  return islist !== "list" ? (
    <div
      ref={drag}
      className={`relative p-4 border rounded-lg bg-white flex flex-col items-center cursor-pointer ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <div className="w-full h-40 flex items-center justify-center bg-gray-100 rounded">
        {file.page_path ? (
          <img
            src={`${BASE_URL}/${file.page_path}`}
            alt={htmlToPlainText(file.title)}
            className="w-full h-full object-cover rounded"
          />
        ) : (
          <FaFileAlt size={60} className="text-gray-600" />
        )}
      </div>

      <p className="text-xs mt-2 text-center">
        {file.page_no !== "0"
          ? htmlToPlainText(file.title)
          : `${t("page")}: ${
              savedLang !== "he"
                ? file.page_no
                : convertToHebrewNumerals(file.page_no)
            }: ${htmlToPlainText(file.title)}`}
      </p>
      <div className="flex space-x-2">
        <button
          className="p-2 bg-red-500 rounded-full text-white"
          onClick={() => {
            if (file.page_path) {
              viewPage(`${BASE_URL}/${file.page_path}`);
            } else {
              alert(t("alert1"));
            }
          }}
        >
          <FaSearch />
        </button>
        <button
          className="p-2 bg-[#fde5c3c4] rounded-full text-black"
          onClick={() => {
            if (file.page_path) {
              printPage(`${BASE_URL}/${file.page_path}`, file.title);
            } else {
              alert(t("alert1"));
            }
          }}
        >
          <FaPrint />
        </button>
        {nocheck == false && (
          <input
            className="ml-2 accent-red-700 cursor-pointer"
            type="checkbox"
            checked={isChecked}
            onChange={() => toggleSelectFile(file.print_page_id)}
          />
        )}
      </div>
    </div>
  ) : (
    <div ref={drag} className="flex items-center border-b p-2">
      <FaFileAlt size={20} className="text-gray-600 mr-2" />
      <span className="text-sm">
        {htmlToPlainText(file.title)} : {t("page")}:{" "}
        {savedLang !== "he"
          ? file.page_no
          : convertToHebrewNumerals(file.page_no)}
      </span>
      <div className="ml-auto flex items-center space-x-2">
        <button
          className="p-2 bg-red-500 rounded-full text-white"
          onClick={() => {
            if (file.page_path) {
              viewPage(`${BASE_URL}/${file.page_path}`);
            } else {
              alert(t("alert1"));
            }
          }}
        >
          <FaSearch size={10} />
        </button>
        <button
          className=" p-2 bg-[#fde5c3c4] rounded-full text-black"
          onClick={() => {
            if (file.page_path) {
              printPage(`${BASE_URL}/${file.page_path}`, file.title);
            } else {
              alert(t("alert1"));
            }
          }}
        >
          <FaPrint size={10} />
        </button>
        <input
          className="ml-2 accent-red-700 cursor-pointer"
          type="checkbox"
          checked={isChecked}
          onChange={() => toggleSelectFile(file.print_page_id)}
        />
      </div>
    </div>
  );
};

const DroppableFolder = ({
  folder,
  setFolders,
  index,
  renameFolder,
  deleteFolder,
  deletepage,
  setPages,
  createpage,
  islist,
  getUserData,
  GetPrints,
  viewPage,
  printPage,
  printPages,
  savedLang
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [iscoverOpen, setIsCoverOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const allpage = 
  folder.cover_img
    ? [
        {
          print_page_id: 0,
          user_id: folder.pages?.[0] ?? "0",
          paid: 0,
          book_page_id: 0,
          book_id: 0,
          page_path: folder.cover_img,
          page_no: " ",
          title: t("cover"),
        },
        ...(folder.pages ?? [])
      ]
    : folder.pages ?? [];


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const uploadCover = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("id", folder.id);
    formData.append("cover_image", selectedFile);

    try {
      const response = await axios.post(`${BASE_URL}/add_cover.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        alert("Cover uploaded successfully!");
        // onCoverUpdate(response.data.cover_url); // Update the cover image in UI
        setIsOpen(false); // Close modal after upload
      } else {
        alert(response.data.message || "Failed to upload cover");
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("An error occurred while uploading the cover");
    } finally {
      setUploading(false);
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: "file",
    drop: async (item) => {
      try {
        const response = await axios.put(
          `${BASE_URL}/api_print.php`,
          new URLSearchParams({
            action: "add_page_in_folder",
            id: item.print_page_id,
            folder_id: folder.id,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        console.log("Response:", response.data);
        GetPrints(getUserData()); // Refresh the data after update
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const removeFileFromFolder = async (fileId) => {
    console.log(folder.id);
    try {
      const response = await axios.put(
        `${BASE_URL}/api_print.php`,
        new URLSearchParams({
          action: "remove_page_from_folder",
          id: fileId,
          folder_id: folder.id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log("Response:", response.data);
      GetPrints(getUserData()); // Refresh the data after update
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    }
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const toggleSelectFile = (fileName) => {
    console.log(selectedFiles);
    setSelectedFiles((prev) =>
      prev.includes(fileName)
        ? prev.filter((file) => file !== fileName)
        : [...prev, fileName]
    );
  };

  const toggleSelectAll = () => {
    if (selectedFiles.length === allpage.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles([
        {
          print_page_id: 0,
          user_id: folder.pages[0] ?? "0",
          paid: 0,
          book_page_id: 0,
          book_id: 0,
          page_path: folder.cover_img,
          page_no: " ",
          title: t("cover"),
        },
        ...folder.pages
      ]);
      
    }
  };
  // const isChecked = file.print_page_id && selectedFiles?.includes(file.print_page_id);
  return (
    <div className="relative">
      {islist !== "list" ? (
        <div
          ref={drop}
          className={`w-full h-60 border-b flex items-center justify-center  rounded p-4 border rounded-lg flex flex-col items-center cursor-pointer ${
            isOver ? "opacity-50" : ""
          }`}
          onClick={() => setIsOpen(true)}
        >
          <FaFolder size={80} className="text-yellow-600" />
          <p className="text-xs mt-2 text-center">{folder.folder}</p>
          <button
            className="absolute top-1 right-1 text-xs"
            onClick={(e) => {
              e.stopPropagation();
              renameFolder(index);
            }}
          >
            <FaEdit />
          </button>
          <button
            className="absolute bottom-1 right-1 text-xs"
            onClick={(e) => {
              e.stopPropagation();
              deleteFolder(index);
            }}
          >
            <FaTrash />
          </button>
        </div>
      ) : (
        <div
          ref={drop}
          className={`p-2 border-b flex items-center cursor-pointer ${
            isOver ? "opacity-50" : ""
          }`}
          onClick={() => setIsOpen(true)}
        >
          <FaFolder size={20} className="text-yellow-600 mr-2" />
          <span className="text-sm">{folder.folder}</span>

          {/* Push buttons to the right */}
          <div className="ml-auto flex items-center space-x-2">
            <button
              className="text-xs"
              onClick={(e) => {
                e.stopPropagation();
                renameFolder(index);
              }}
            >
              <FaEdit />
            </button>
            <button
              className="text-xs text-red-500"
              onClick={(e) => {
                e.stopPropagation();
                deleteFolder(index);
              }}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      )}

      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 border shadow-lg rounded-lg w-[90%] max-w-2xl max-h-[80vh] min-h-[300px] overflow-y-auto relative">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">{folder.folder}</h3>

                <button
                  onClick={() => setIsOpen(false)}
                  className="text-red-500"
                >
                  <FaTimes />
                </button>
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  onClick={() => setIsCoverOpen(true)}
                  className="flex justify-center px-3 py-2 border border-gray-400 rounded-md items-center gap-1 h-10 text-sm"
                >
                  {t("add_cover")}
                </button>
                {iscoverOpen && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 border shadow-lg rounded-lg w-[90%] max-w-md relative">
                      <h3 className="text-lg font-bold mb-4">
                        {t("add_cover")}
                      </h3>

                      {/* File Input */}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="w-full border p-2 rounded-md"
                      />

                      {/* Buttons */}
                      <div className="flex justify-end gap-2 mt-4">
                        <button
                          onClick={() => setIsCoverOpen(false)}
                          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                        >
                          {t("cancel")}
                        </button>
                        <button
                          onClick={uploadCover}
                          disabled={uploading}
                          className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:bg-gray-400"
                        >
                          {uploading ? t("uploading") : t("upload")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {folder.pages.length > 0 && (
                  <button
                    className="flex justify-center px-3 py-2 border border-gray-400 rounded-md items-center gap-1 h-10 text-sm"
                    onClick={() => {
                      const imagesToPrint = allpage
                      .filter((file) => file.page_path) // Only include files where page_path is not null
                      .sort((a, b) => (a.id === 0 ? -1 : b.id === 0 ? 1 : 0)) // Move file with id === 0 to the first position
                      .map((file) => ({
                        imageSrc: `${BASE_URL}/${file.page_path}`,
                        title: file.title || "Page",
                      }));
                    

                      console.log(imagesToPrint);
                      printPages(imagesToPrint);
                    }}
                  >
                    {t("select_all_print")}
                  </button>
                )}

                <label className="flex justify-center px-3 py-2 border border-gray-400 rounded-md items-center gap-1 h-10 text-sm cursor-pointer">
                  {t("select_all")}
                  <input
                    className="ml-1 accent-red-700 cursor-pointer"
                    type="checkbox"
                    onChange={toggleSelectAll}
                    checked={
                       allpage.length > 0
                        ? selectedFiles.length === allpage.length
                        : false
                    }
                  />
                </label>

                {selectedFiles.length > 0 && (
                  <button
                    className="flex justify-center px-3 py-2 border border-gray-400 rounded-md items-center gap-1 h-10 text-sm"
                    onClick={() => {
                      console.log(selectedFiles);
                      const imagesToPrint = selectedFiles
  .filter((file) => file.page_path) // Only include files where page_path is not null
  .sort((a, b) => (a.id === 0 ? -1 : b.id === 0 ? 1 : 0)) // Move file with id === 0 to the first position
  .map((file) => ({
    imageSrc: `${BASE_URL}/${file.page_path}`,
    title: file.title || "Page",
  }));

                      // const imagesToPrint = selectedFiles.map((file) => ({
                      //   imageSrc: `${BASE_URL}/${file.page_path}`,
                      //   title: file.title || "Page",
                      // }));
                      printPages(imagesToPrint);
                    }}
                  >
                    {t("print")}
                  </button>
                )}
              </div>

              <br />
              <div className="grid grid-cols-2 gap-4 min-h-[200px]">
                {folder.cover_img && (
                  <div className="p-4 border rounded-lg bg-gray-100 flex flex-col items-center relative">
                    <DraggableFile
                      file={{
                        print_page_id: 0,
                        user_id: folder.pages[0] ?? "0",
                        paid: 0,
                        book_page_id: 0,
                        book_id: 0,
                        page_path: folder.cover_img,
                        page_no: " ",
                        title: t("cover"),
                      }}
                      //  setPages={setPages}
                      printPage={printPage}
                      viewPage={viewPage}
                      isdelete={false}
                      nocheck={true}
                    />

                    <input
                      className=" absolute bottom-1 right-1  ml-2 accent-red-700 cursor-pointer"
                      type="checkbox"
                      onChange={() =>
                        toggleSelectFile({
                          print_page_id: 0,
                          user_id: folder.pages[0] ?? "0",
                          paid: 0,
                          book_page_id: 0,
                          book_id: 0,
                          page_path: folder.cover_img,
                          page_no: " ",
                          title: t("cover"),
                        })
                      }
                      checked={selectedFiles.some(
                        (f) => f.page_path === folder.cover_img
                      )}
                    />
                  </div>
                )}
                {folder.pages.length > 0 ? (
                  folder.pages.map((file) => (
                    <div
                      key={file.id}
                      className="p-4 border rounded-lg bg-gray-100 flex flex-col items-center relative"
                    >
                      <DraggableFile
                        file={file}
                        setPages={setPages}
                        printPage={printPage}
                        viewPage={viewPage}
                        isdelete={false}
                        nocheck={true}
                      />
                      <button
                        className="absolute top-1 right-1 text-xs text-red-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFileFromFolder(file.print_page_id);
                        }}
                        title={t("send_back_to")}
                      >
                        <FaRedo />
                      </button>
                      <input
                        className=" absolute bottom-1 right-1  ml-2 accent-red-700 cursor-pointer"
                        type="checkbox"
                        onChange={() => toggleSelectFile(file)}
                        checked={selectedFiles?.includes(file)}
                      />
                    </div>
                  ))
                ) : (
                  <p className="text-center col-span-2">{t("no_file_in")}</p>
                )}
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default MyPrintables;
