import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import axios from "axios";
import BASE_URL from "./constant";
import { useTranslation } from 'react-i18next'; 
import { Link, useNavigate, useParams } from "react-router-dom";
import htmlToPlainText from "./htmltotext";
import NODE_BASE_URL from "./node_constant";


const Search = () => {
  const [selectedLocation, setSelectedLocation] = useState('Jerusalem');
  const [zmanim, setZmanim] = useState([]);
  const[results, setResults]=useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchZmanim(selectedLocation);
  }, [selectedLocation]);

  const fetchZmanim = async (location) => {
    try {
      // Example using Hebcal API to fetch zmanim
      const response = await axios.get(`https://www.hebcal.com/shabbat/?cfg=json&geo=${location}&m=50`);
      const zmanimData = response.data.items;
      setZmanim(zmanimData);
    } catch (error) {
      console.error('Error fetching zmanim:', error);
    }
  };

  // Handle change in location select
  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setSelectedLocation(newLocation);
    fetchZmanim(newLocation); // Fetch zmanim data for the newly selected location
  };

  

  const [searchResults, setSearchResults] = useState([]);
  const {t}=useTranslation();
  const fetchBooks = async (query) => {
    console.log(query);
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/books?name=${query}`);
      const bookData = response.data;
      console.log(bookData);
      const formattedBooks = bookData.map(book => ({
        id: book.id,
        name:htmlToPlainText(book.title),
        images: book.images[0]
      }));
      console.log(formattedBooks)
      setSearchResults(formattedBooks);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };
  const fetchBooksbySearch = async (query) => {
    console.log(query);
   
      axios
        .post(`${NODE_BASE_URL}/search`, {
          word: query,
        })
        .then((res) => {
          console.log(res.data);
          const formattedBooks = res.data.map(book => ({
            id: book.id,
            name:htmlToPlainText(book.title),
           images:`${BASE_URL}/uploads/images/Books_image0_2024-08-08T19-11-27.png`
          }));
          console.log(formattedBooks)
          // setSearchResults(formattedBooks);
          setResults(formattedBooks); // Assuming the response has a services array
        })
        .catch((err) => console.log(err));
    // try {
    //   const response = await axios.get(`${BASE_URL}/api_book.php/books?name=${query}`);
    //   const bookData = response.data;
    //   console.log(bookData);
    //   const formattedBooks = bookData.map(book => ({
    //     id: book.id,
    //     name:htmlToPlainText(book.title),
    //     images: book.images[0]
    //   }));
    //   console.log(formattedBooks)
    //   setSearchResults(formattedBooks);
    // } catch (error) {
    //   console.error('Error fetching books:', error);
    // }
  };
  const handleOnSearch = (query) => {
    if (query.length === 0) {
      setSearchResults([]);
    } else {
      fetchBooks(query);
    }
  };
  const handlebookSearch = (query) => {
    // if (query.length === 0) {
    //   setResults([]);
    // } else {
      fetchBooksbySearch(query);
    // }
  };

  const handleOnSelect = (item) => {
     navigate(`/pages/Product/${item.id}`);
    console.log(item);
  };

  const formatResult = (book) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={book.images } alt={book.title} style={{ marginRight: '30px', width: '30px', height: 'auto' }} />
        <span style={{ textAlign: 'left' }}>{htmlToPlainText(book.title??book.name)}</span>
      </div>
    );
  };

  return (
    <div className="shadow-slate-100 shadow-lg  bg-opacity-70 bg-zinc-100 p-8 my-5 mt-0 w-[90%] m-auto rounded-md">
      <h1 className="text-4xl text-red-900 mt-4"> {t('welcome')}</h1>
      <p>{t('welcome_desc')}</p>
      <div className="flex w-full my-8">
       <div className="w-[100%]">
        <ReactSearchAutocomplete
          items={searchResults}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          autoFocus
          placeholder={t("search_book")}
          showItemsOnFocus
          styling={{
            borderRadius: "12px", //
            hoverBackgroundColor: "#eee",
            boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
          }}
          className="rounded-l-full sreach-book"
          formatResult={formatResult}
        />
        </div>
     
      </div>

    </div>
  );
};

export default Search;
